import React from 'react';

// component
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsGrid from '../../components/WmsGrid/WmsGrid.jsx';
import { AutoRateShoppingRuleModel } from './model/auto-rate-shopping-rule-model.component.jsx';
import { CarrierMarkupModel } from './model/carrier-markup.component.jsx';
import { CarrierServiceLevelsModel } from './model/carrier-service-levels.component.jsx';
import CarrierSelectAccountsModal from './model/carrier-select-accounts-modal.component';


//services
import * as GridConfig from './small-parcel-smart-settings-config.jsx';
import * as GlobalService from '../../global_services';
import * as smallParcelServices from '../small-parcel-settings.services.jsx';


export class SmallParcelSmartSettings extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate
        let countries = [...GlobalService.GetCountries()];

        this.rateShoppingGridColumns = GridConfig.getRateShoppingGridColumns().columns;
        this.rateShoppingGridSchema = GridConfig.getRateShoppingGridSchema().schema;
        this.rights = GlobalService.GetRights();
        this.isEnableProvider = this.rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.rights['Integrations.SmallParcelPartner'] == 'ParcelApi';
        this.optionsDutyBillType = [
            { text: "Not Specified", value: "100" },
            { text: "Bill Sender", value: "102" },
            { text: "Bill Recipient", value: "103" }
        ];
        this.optionsReference = [
            { text: "Reference Number", value: "RefNum" },
            { text: "PO Number", value: "PoNum" },
            { text: "SKU (qty)", value: "Sku(Qty)" },
            { text: "Transaction ID", value: "TransId" },
            { text: "BOL Number", value: "BolNum" },
            { text: "Dept. Number", value: "DeptNum" },
            { text: "UCC128", value: "UCC128" },
            { text: "Carrier Notes", value: "CarrierNotes" },
            { text: "Customer Name", value: "CustomerName" }
        ];
        this.optionsUsps3rdParty = [
            { text: "Endicia", value: "Endicia" },
            { text: "Stamps.com", value: "Stamps.com" }
        ];
        this.state = {
            optionsCountry: countries,
            isOpenAddAutoRateShoppingRuleModel: false,
            SmallParcelProcessReturnStreetAddress2: false,
            carrierShipmentServices: [],
            isOpenCarrierMarkupModel: false,
            isOpenCarrierServiceLevelsModel: false,
            menuOptions: [],
            optionsTemplateReports: [],
            optionsPackingReports: [],
            cartonLabelsOptions: [],
            isIntegratedPackingSlips: false,
        }
    }

    componentDidMount() {
        // Get Label Template Options From The Get Reports API
        if(this.props.sharedSelectedCustomer && this.props.sharedSelectedCustomer.id) {
            GlobalService.initialPrintMenu(this.props.sharedSelectedCustomer.id).then((response) => {
                if (response) {
                    let reprintShippingLabelMenus = [];

                    // Get Label Template From The Reports API
                    response.map(function (e) {
                        if(e.CategoryName == "Reprint Shipping Label") {
                            reprintShippingLabelMenus = e.Reports;
                        }
                    });

                    // Check If Label Template Deleted Then Set Default Label Template
                    if(this.props.sharedSmallParcelSettings.SmallParcelDefaultLabelTemplate != '' && reprintShippingLabelMenus.length) {
                        let selLabelTemplate = reprintShippingLabelMenus.find((elem) => elem.Name == this.props.sharedSmallParcelSettings.SmallParcelDefaultLabelTemplate);
                        if(selLabelTemplate == undefined) {
                            this.props.sharedSmallParcelSettings.SmallParcelDefaultLabelTemplate = 'Default';
                        }
                    } else {
                        this.props.sharedSmallParcelSettings.SmallParcelDefaultLabelTemplate = 'Default';
                    }

                    this.setState({
                        menuOptions: reprintShippingLabelMenus
                    });
                }
            });

            // Get Carton Label Template
            const defaultCartonLabelOption = { Name: "Not specified" };
            smallParcelServices.getCartonLabelTemplates(this.props.sharedSelectedCustomer.id)
                .then((response) => {
                    const cartonLabelsOptions = response ? [defaultCartonLabelOption, ...response.CartonLabels] : [defaultCartonLabelOption];
                    this.setState({ cartonLabelsOptions });
                });

            // Get Packing Slip Report
            let param_payload = {
                'customerId': this.props.sharedSelectedCustomer.id
            }

            smallParcelServices.getPackingSlipReports(param_payload).then((response) => {
                if (response && response.status == 200) {
                    let responseData = response.data;
                    if(responseData.length >= 1) {
                        let tempOptionsTemplateReports = responseData[0].Templates;
                        tempOptionsTemplateReports.unshift({
                            "Name": '',
                            "Id": null
                        });

                        let tempOptionsPackingReports = [];
                        tempOptionsPackingReports.push({
                            "Name": '',
                            "TemplatedRunner": null
                        });
                        for (let x = 0; x < responseData.length; x++) {
                            if(responseData[x].Reports.length) {
                                for (let y = 0; y < responseData[x].Reports.length; y++) {
                                    tempOptionsPackingReports.push(responseData[x].Reports[y]);
                                }
                            }
                        }
                        this.setState({
                            optionsTemplateReports: tempOptionsTemplateReports,
                            optionsPackingReports: tempOptionsPackingReports
                        });
                    }
                }
            });
        }
        if (this.props.sharedSmallParcelSettings && this.props.sharedSmallParcelSettings.Address2) {
            this.setState({ SmallParcelProcessReturnStreetAddress2: true });
        }

        if (this.props.sharedSmallParcelSettings && this.props.sharedSmallParcelSettings.SmallParcelPackSlip && this.props.sharedSmallParcelSettings.SmallParcelAddressTemplate) {
            this.setState({ isIntegratedPackingSlips: true });
        }

        var tmpShipmentServices = {};
        _.map(this.props.sharedShipEngineCarrier, function (x) {
            tmpShipmentServices[x.Carrier.toLowerCase()] = x.ShipmentServices
        });
        this.setState({ carrierShipmentServices: tmpShipmentServices });
    }

    handleSettingsChange = (event) => {

        let name = event.target.name;
        let parentObj = this.props.sharedSmallParcelSettings;

        if (event.target.type == 'checkbox') {
            if(name === "IntegratedPackingSlips") {
                if(this.state.isIntegratedPackingSlips) {
                    this.setState({ isIntegratedPackingSlips: false });
                } else {
                    this.setState({ isIntegratedPackingSlips: true });
                }
                parentObj["SmallParcelPackSlip"] = "";
                parentObj["SmallParcelAddressTemplate"] = "";
            }
            parentObj[name] = !parentObj[name];
        } else if (event.target.type == 'radio') {
            parentObj.SmallParcelUspsReturnType = event.target.name;
        } else {
            parentObj[name] = event.target.value;
            if(name === "SmallParcelDefaultLabelTemplate" && event.target.value !== "Default") {
                parentObj["SmallParcelPackSlip"] = "";
                parentObj["SmallParcelAddressTemplate"] = "";
            }
            if(name === "SmallParcelPackSlip" && event.target.value === "") {
                parentObj["SmallParcelAddressTemplate"] = "";
            }
        }

        let settingObj = Object.assign({}, this.props.sharedSmallParcelSettings, parentObj);
        this.props.onSettingsDataSet(settingObj);
    }

    handleAddressCheckboxChange = (e) => {
        if (e.target.checked == false) {
            let settingObj = Object.assign({}, this.props.sharedSmallParcelSettings, { Address2: '' });
            this.props.onSettingsDataSet(settingObj);
        }
        this.setState({ SmallParcelProcessReturnStreetAddress2: e.target.checked });
    }

    handleRateShoppingChange = (event) => {

        let arrRateShopping = [];
        let parentObj = this.props.sharedSmallParcelSettings;
        let carrrierIds = this.props.sharedSmallParcelSettings.SmallParcelRateShoppingCarrierIds;
        let smallParcelRateShopFilter = this.props.sharedSmallParcelSettings.SmallParcelRateShopFilter;

        if (carrrierIds != '' && carrrierIds != null) {
            arrRateShopping = carrrierIds.split(',');
        }

        let position = arrRateShopping.indexOf(event.target.name);

        if (position != -1)
            arrRateShopping.splice(position, 1);
        else
            arrRateShopping.push(event.target.name);

        // If Small Parcel Rate Shopping Filter Exist And Unchecked Target Checkbox
        if(!event.target.checked && smallParcelRateShopFilter) {
            var uncheckedElement = event.target.name;
            if(uncheckedElement === "DHLGM") {
                uncheckedElement = "DHL eCommerce";
            } else if(uncheckedElement === "FDXE") {
                uncheckedElement = "FedEx";
            } else if(uncheckedElement === "ENDCA") {
                uncheckedElement = "USPS";
            } else if(uncheckedElement === "PBCS") {
                uncheckedElement = "PitneyBowesStd";
            } else if(uncheckedElement === "AMZN") {
                uncheckedElement = "Amazon Buy";
            }
            var tempSmallParcelRateShopFilter = JSON.parse(smallParcelRateShopFilter);
            var tempSPRSF = tempSmallParcelRateShopFilter.carriers;
            if(tempSPRSF[uncheckedElement.toLowerCase()]) {
                delete tempSPRSF[uncheckedElement.toLowerCase()];
            }
            parentObj.SmallParcelRateShopFilter = JSON.stringify({carriers: tempSPRSF});
        }

        parentObj.SmallParcelRateShoppingCarrierIds = arrRateShopping.join(',');
        let settingObj = Object.assign({}, this.props.sharedSmallParcelSettings, parentObj);
        this.props.onSettingsDataSet(settingObj);
    }

    eventClickAddLogic = () => {
        let parentObj = this.props.sharedSmallParcelSettings;
        let newMapping = parentObj.AutoRateShoppingMapping;
        let newLogic = parentObj.AutoRateShoppingLogic;

        if (newMapping == "" || newLogic == "") {
            return;
        }

        let sprsLogics = [];

        if (parentObj.SmallParcelRateShoppingLogic) {
            sprsLogics = Object.assign([], parentObj.SmallParcelRateShoppingLogic);
        }

        for (let i = 0; i < sprsLogics.length; i++) {
            if (sprsLogics[i].Mapping.toLowerCase() == newMapping.toLowerCase()) {
                GlobalService.notification("error", "Mapping names must be unique");
                return;
            }
        }

        parentObj.AutoRateShoppingMapping = "";
        parentObj.AutoRateShoppingLogic = "";

        sprsLogics.push({ Mapping: newMapping, Logic: newLogic });

        parentObj.SmallParcelRateShoppingLogic = sprsLogics;

        // let settingObj = Object.assign({}, this.props.sharedSmallParcelSettings, parentObj);
        this.props.onSettingsDataSet(parentObj);
        this.closeAddAutoRateShoppingRuleModel();
    }

    openAddAutoRateShoppingRuleModel = () => {
        this.setState({ isOpenAddAutoRateShoppingRuleModel: true });
    }
    closeAddAutoRateShoppingRuleModel = () => {
        this.setState({ isOpenAddAutoRateShoppingRuleModel: false });
    }
    onRemove = (e) => {
        setTimeout(() => {
            console.log("onRemove call");
            var grid = $('#gridRateShoppingLogic').data('kendoGrid');
            let parentObj = this.props.sharedSmallParcelSettings;

            parentObj.SmallParcelRateShoppingLogic = grid._data;
            this.props.onSettingsDataSet(parentObj);
        }, 100);
    }

    openCarrierMarkup = () => {
        this.setState({
            isOpenCarrierMarkupModel: true
        });
    }
    closeCarrierMarkup = () => {
        this.setState({
            isOpenCarrierMarkupModel: false
        });
    }

    openCarrierServiceLevels = () => {
        this.setState({
            isOpenCarrierServiceLevelsModel: true
        });
    }
    closeCarrierServiceLevels = () => {
        this.setState({
            isOpenCarrierServiceLevelsModel: false
        });
    }
    saveCarrierServiceLevels = (newSettingsObj) => {
        let tempNewSettingsObj = Object.assign({}, this.props.sharedSmallParcelSettings, newSettingsObj);
        this.props.onSettingsDataSet(tempNewSettingsObj);
    }

    openSelectCarrierAccounts = () => {
        this.setState({ isOpenSelectCarrierAccountsModal: true });
    }
    closeSelectCarrierAccounts = () => {
        this.setState({ isOpenSelectCarrierAccountsModal: false });
    }

    render() {
        const renderSettings = this.props.sharedSmallParcelSettings;

        const renderArrRateShopping =
            typeof renderSettings.SmallParcelRateShoppingCarrierIds != 'undefined' &&
                renderSettings.SmallParcelRateShoppingCarrierIds != null &&
                renderSettings.SmallParcelRateShoppingCarrierIds != '' ?
                renderSettings.SmallParcelRateShoppingCarrierIds.split(',') : [];


        return (
            <div className="small-parcel-other-settings-wrapper">
                <WmsCollapse
                    id={'smallParcelGeneralSettings'}
                    headerLabel={this.translate('Label_General')}
                    showCollapseText={false}
                    showCollapseIcon={true}>
                    <div className="inline-element">
                        <WmsCheckBox
                            id={'SmallParcelReturnShippingCost'}
                            name={'SmallParcelReturnShippingCost'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Label_Include_Shipping_Cost")}
                            checked={renderSettings.SmallParcelReturnShippingCost}
                            value={renderSettings.SmallParcelReturnShippingCost}
                            wmsSelector={this.props.selector + "ShippingCost"}
                            parentClassName="shipping-cost-wrapper" />

                        {Object.prototype.hasOwnProperty.call(this.rights, 'Shipping.SmallParcelHighVolumeProcessing') &&

                            <WmsCheckBox
                                id={'SmallParcelHighVolumeDefaultChecked'}
                                name={'SmallParcelHighVolumeDefaultChecked'}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate("Label_Process_High_Volume_Default")}
                                checked={renderSettings.SmallParcelHighVolumeDefaultChecked}
                                value={renderSettings.SmallParcelHighVolumeDefaultChecked}
                                wmsSelector={this.props.selector + 'highVolume'}
                                parentClassName="process-high-volume-default" />
                        }
                        <WmsCheckBox
                            id={'SmallParcelZplPrintDefault'}
                            name={'SmallParcelZplPrintDefault'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Label_Print_Zpl_Labels_Default")}
                            checked={renderSettings.SmallParcelZplPrintDefault}
                            value={renderSettings.SmallParcelZplPrintDefault}
                            wmsSelector={this.props.selector + 'zplPrintDefault'}
                            parentClassName="process-high-volume-default" />

                        <WmsCheckBox
                            id={'SmallParcelCarrierSelectionV2'}
                            name={'SmallParcelCarrierSelectionV2'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Label_SmallParcelCarrierSelectionV2")}
                            checked={renderSettings.SmallParcelCarrierSelectionV2}
                            value={renderSettings.SmallParcelCarrierSelectionV2}
                            wmsSelector={this.props.selector + 'smallParcelCarrierSelectionV2'}
                            parentClassName="process-high-volume-default" />

                        <WmsCheckBox
                            id={'SmallParcelSendFulfillInvSalePrice'}
                            name={'SmallParcelSendFulfillInvSalePrice'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Label_SmallParcelSendFulfillInvSalePrice")}
                            checked={renderSettings?.SmallParcelSendFulfillInvSalePrice}
                            value={renderSettings?.SmallParcelSendFulfillInvSalePrice}
                            wmsSelector={this.props.selector + 'smallParcelSendFulfillInvSalePrice'}
                            disabled={!renderSettings.FulfillmentInvoicing}
                            parentClassName="process-high-volume-default" />

                        <WmsCheckBox
                            id={'IntegratedPackingSlips'}
                            name={'IntegratedPackingSlips'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Label_IntegratedPackingSlips")}
                            checked={this.state.isIntegratedPackingSlips}
                            value={this.state.isIntegratedPackingSlips}
                            wmsSelector={this.props.selector + 'integratedPackingSlips'}
                            parentClassName="process-high-volume-default" />
                    </div>
                    <div className="inline-element options-wrapper">
                        {!this.isEnableProvider && (
                            <WmsDropdown
                                id="SmallParcelUsps3rdParty"
                                name="SmallParcelUsps3rdParty"
                                label={this.translate("Label_USPS_3rd_Party_Connection")}
                                wmsSelector={this.props.selector + "Usps3rdParty"}
                                value={renderSettings.SmallParcelUsps3rdParty}
                                onChangeFunc={this.handleSettingsChange}
                                options={this.optionsUsps3rdParty}
                                valueField="value"
                                textField="text"
                                blankFirstOption={true}
                                blankFirstOptionText={this.translate('Label_Not_Specified')}
                                extraWrapperClass={'usps-3rd-party-wrapper'}
                            />
                        )}
                        <WmsDropdown
                            id="SmallParcelIntlDutyBillType"
                            name="SmallParcelIntlDutyBillType"
                            label={this.translate("Window_Title_Label_SmartParcel_Duty_Bill")}
                            wmsSelector={this.props.selector + "DutyBillType"}
                            value={renderSettings.SmallParcelIntlDutyBillType}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.optionsDutyBillType}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                            extraWrapperClass={'international-bill-type-wrapper'}
                        />
                        <WmsDropdown
                            id="PieceShipRef"
                            name="PieceShipRef"
                            label={this.translate("Label_Small_Parcel_Reference") + " 1"}
                            wmsSelector={this.props.selector + "Reference"}
                            value={typeof renderSettings.PieceShipRef == 'undefined' ? '' : renderSettings.PieceShipRef}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.optionsReference}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Not_Specified')}
                            extraWrapperClass={'label-reference-wrapper'}
                        />
                        <WmsDropdown
                            id="PieceShipRef1"
                            name="PieceShipRef1"
                            label={this.translate("Label_Small_Parcel_Reference") + " 2"}
                            wmsSelector={this.props.selector + "Reference1"}
                            value={typeof renderSettings.PieceShipRef1 == 'undefined' ? '' : renderSettings.PieceShipRef1}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.optionsReference}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Not_Specified')}
                            extraWrapperClass={'label-reference-wrapper'}
                        />
                        <WmsDropdown
                            id="PieceShipRef2"
                            name="PieceShipRef2"
                            label={this.translate("Label_Small_Parcel_Reference") + " 3"}
                            wmsSelector={this.props.selector + "Reference2"}
                            value={typeof renderSettings.PieceShipRef2 == 'undefined' ? '' : renderSettings.PieceShipRef2}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.optionsReference}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Not_Specified')}
                            extraWrapperClass={'label-reference-wrapper'}
                        />
                    </div>
                    <div className="inline-element options-wrapper">
                        <WmsDropdown
                            id="smallParcelDefaultLabelTemplate"
                            name="SmallParcelDefaultLabelTemplate"
                            label={this.translate("Label_Label_Template")}
                            wmsSelector={this.props.selector + "SmallParcelDefaultLabelTemplate"}
                            value={renderSettings.SmallParcelDefaultLabelTemplate}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.state.menuOptions}
                            valueField="Name"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass={'label-reference-wrapper'}
                        />
                        <WmsDropdown
                            id="smallParcelDefaultCartonLabel"
                            name="SmallParcelDefaultCartonLabel"
                            label={this.translate("Label_Carton_Label_Template")}
                            wmsSelector={this.props.selector + "SmallParcelDefaultCartonLabel"}
                            value={renderSettings.SmallParcelDefaultCartonLabel}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.state.cartonLabelsOptions}
                            valueField="Name"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass={'label-reference-wrapper'}
                        />
                        {this.state.isIntegratedPackingSlips && (
                            <>
                                <WmsDropdown
                                    id="smallParcelPackSlip"
                                    name="SmallParcelPackSlip"
                                    label={this.translate("Label_Package_Slip_Type")}
                                    wmsSelector={this.props.selector + "SmallParcelPackSlip"}
                                    value={renderSettings.SmallParcelPackSlip}
                                    onChangeFunc={this.handleSettingsChange}
                                    options={this.state.optionsPackingReports}
                                    valueField="TemplatedRunner"
                                    textField="Name"
                                    parentClassName="form_group"
                                    extraWrapperClass={'label-reference-wrapper'}
                                    disabled={renderSettings.SmallParcelDefaultLabelTemplate !== 'Default'}
                                />
                                <WmsDropdown
                                    id="smallParcelAddressTemplate"
                                    name="SmallParcelAddressTemplate"
                                    label={this.translate("Label_Packing_Slip_Address_Configuration")}
                                    wmsSelector={this.props.selector + "SmallParcelAddressTemplate"}
                                    value={renderSettings.SmallParcelAddressTemplate}
                                    onChangeFunc={this.handleSettingsChange}
                                    options={this.state.optionsTemplateReports}
                                    valueField="Id"
                                    textField="Name"
                                    parentClassName="form_group"
                                    extraWrapperClass={'label-reference-wrapper'}
                                    disabled={renderSettings.SmallParcelDefaultLabelTemplate !== 'Default' || !renderSettings.SmallParcelPackSlip}
                                />
                            </>
                        )}
                    </div>
                    <WmsButton
                        label={this.translate("Label_Edit_Carrier_Markup")}
                        wmsSelector={this.selector + "CarrierMarkup"}
                        onClickFunc={this.openCarrierMarkup}
                        icon="fa-percent"
                        showToolTip={this.props.sharedCarrierAccountsGridData.length == 0}
                        disabled={this.props.sharedCarrierAccountsGridData.length == 0}
                        tooltipText={this.translate('Carrier_MARK_DISABLE_TEXT')} />
                </WmsCollapse>
                <WmsCollapse
                    id={'SmartParcelReturnSettings'}
                    headerLabel={this.translate('NavBarV1_Btn_Returns')}
                    showCollapseText={false}
                    showCollapseIcon={true}>
                    <div className="inline-element">
                        <WmsDropdown
                            label={this.translate("Window_Title_Label_FedEx_Return_Service")}
                            id="SmallParcelReturnFedExService"
                            name="SmallParcelReturnFedExService"
                            wmsSelector={this.props.selector + "FedExService"}
                            value={renderSettings.SmallParcelReturnFedExService}
                            onChangeFunc={this.handleSettingsChange}
                            options={renderSettings.carrierFedEx}
                            valueField="Code"
                            textField="Description"
                            parentClassName="form_group"
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Not_Specified')}
                            extraWrapperClass={'fedex-return-service-wrapper'}
                        />
                        <WmsDropdown
                            id="SmallParcelReturnUpsService"
                            name="SmallParcelReturnUpsService"
                            label={this.translate("Window_Title_Label_UPS_Return_Service")}
                            wmsSelector={this.props.selector + "UpsService"}
                            value={renderSettings.SmallParcelReturnUpsService}
                            onChangeFunc={this.handleSettingsChange}
                            options={renderSettings.carrierUPS}
                            valueField="Code"
                            textField="Description"
                            parentClassName="form_group"
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Not_Specified')}
                            extraWrapperClass={'ups-return-service-wrapper'}
                        />
                        {!this.isEnableProvider &&(
                            <WmsDropdown
                                id="SmallParcelUspsReturnType"
                                name="SmallParcelUspsReturnType"
                                label={this.translate("Label_USPS_Return_Service")}
                                wmsSelector={this.props.selector + "USPSReturnService"}
                                value={renderSettings.SmallParcelUspsReturnType}
                                onChangeFunc={this.handleSettingsChange}
                                options={[{
                                    'text': this.translate("Window_Title_Label_Standard_Return"),
                                    'value': "103"
                                }, {
                                    'text': this.translate("Window_Title_Label_Pay_on_Use"),
                                    'value': "109"
                                }]}
                                valueField="value"
                                textField="text"
                                parentClassName="form_group"
                                extraWrapperClass={'ups-return-service-wrapper'}
                            />
                        )}
                        {this.isEnableProvider &&(
                            <>
                                <WmsDropdown
                                    id="SmallParcelReturnPitneyExpService"
                                    name="SmallParcelReturnPitneyExpService"
                                    label={this.translate("Label_USPS_Pitney_Bowes_Return_Service")}
                                    wmsSelector={this.props.selector + "SmallParcelReturnPitneyExpService"}
                                    value={renderSettings.SmallParcelReturnPitneyExpService}
                                    onChangeFunc={this.handleSettingsChange}
                                    options={[{
                                        'text': "First-Class Package Return Service",
                                        'value': "07"
                                    }, {
                                        'text': "Ground Return Service",
                                        'value': "848"
                                    }, {
                                        'text': "Priority Mail Return Service",
                                        'value': "02"
                                    }]}
                                    valueField="value"
                                    textField="text"
                                    parentClassName="form_group"
                                    extraWrapperClass={'ups-return-service-wrapper'}
                                />
                                <WmsDropdown
                                    id="SmallParcelReturnPitneyStdService"
                                    name="SmallParcelReturnPitneyStdService"
                                    label={this.translate("Label_Pitney_Bowes_Return_Service")}
                                    wmsSelector={this.props.selector + "SmallParcelReturnPitneyStdService"}
                                    value={renderSettings.SmallParcelReturnPitneyStdService}
                                    onChangeFunc={this.handleSettingsChange}
                                    options={[{
                                        'text': "First Class Mail",
                                        'value': "876"
                                    }, {
                                        'text': "Parcel Select Ground",
                                        'value': "877"
                                    }, {
                                        'text': "Priority Mail",
                                        'value': "878"
                                    }]}
                                    valueField="value"
                                    textField="text"
                                    parentClassName="form_group"
                                    extraWrapperClass={'ups-return-service-wrapper'}
                                />
                            </>
                        )}
                    </div>
                    {/* {Object.prototype.hasOwnProperty.call(this.rights, 'Shipping.SmallParcelReturnLabelEnabled') &&
                        <div className="inline-element wms-checkbox-wrapper">
                            <WmsCheckBox
                                id={'SmallParcelReturnDefaultChecked'}
                                name={'SmallParcelReturnDefaultChecked'}
                                onChangeFunc={this.handleSettingsChange}
                                label={
                                    this.translate("Window_Title_Label_SmartParcel_Return_Label_Checked")
                                }
                                checked={renderSettings.SmallParcelReturnDefaultChecked}
                                value={renderSettings.SmallParcelReturnDefaultChecked}
                                wmsSelector={this.props.selector + "DutyAndTaxes"} />

                            <span className="wms-smartparcel-note">
                                {"(" + this.translate("Window_Title_Label_SmartParcel_Return_Label_Checked_Note") + " )"}
                            </span>
                        </div>
                    } */}
                    <div className="inline-element">
                        <WmsInput
                            id='Company'
                            inputName='Company'
                            wmsSelector={this.props.selector + 'Company'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Company')}
                            value={renderSettings.Company}
                            extraWrapperClass="company-wrapper" />

                        <WmsInput
                            id='Name'
                            inputName='Name'
                            wmsSelector={this.props.selector + 'Name'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Name')}
                            value={renderSettings.Name}
                            extraWrapperClass="name-wrapper" />
                    </div>
                    <div className="inline-element">
                        <WmsDropdown
                            id="Country"
                            name="Country"
                            label={this.translate("Label_Return_Address_Country")}
                            wmsSelector={this.props.selector + "Country"}
                            value={renderSettings.Country}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.state.optionsCountry}
                            textField='Name'
                            valueField='CountryCode'
                            extraWrapperClass="country-wrapper "
                        />

                        <WmsInput
                            id='Phone'
                            inputName='Phone'
                            wmsSelector={this.props.selector + 'Phone'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Phone')}
                            value={renderSettings.Phone}
                            extraWrapperClass="phone-number-wrapper" />

                        <WmsInput
                            id='Code'
                            inputName='Code'
                            wmsSelector={this.props.selector + 'Code'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Window_Title_Label_SmartParcel_Code')}
                            value={renderSettings.Code}
                            extraWrapperClass="code-wrapper" />
                    </div>

                    <div className="inline-element">
                        <WmsInput
                            id='Address1'
                            inputName='Address1'
                            wmsSelector={this.props.selector + 'Address1'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Return_Street_Address')}
                            value={renderSettings.Address1}
                            extraWrapperClass="street-address-wrapper" />

                        {/* TODO: not in smart parcel settings */}
                        <WmsCheckBox
                            id={'SmallParcelProcessReturnStreetAddress2'}
                            name={'SmallParcelProcessReturnStreetAddress2'}
                            onChangeFunc={this.handleAddressCheckboxChange}
                            label={this.translate("Label_Return_Street_Address_2_Optional")}
                            checked={this.state.SmallParcelProcessReturnStreetAddress2}
                            value={this.state.SmallParcelProcessReturnStreetAddress2}
                            wmsSelector={this.props.selector + "HighVolumeDefault"}
                            parentClassName="return-street-address-chk-wrapper" />
                    </div>

                    {(renderSettings.Address2 || this.state.SmallParcelProcessReturnStreetAddress2) && (
                        <WmsInput
                            id='Address2'
                            inputName='Address2'
                            wmsSelector={this.props.selector + 'Address2'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Return_Street_Address') + " 2"}
                            value={renderSettings.Address2}
                            extraWrapperClass="street-address-wrapper" />
                    )}

                    <div className="inline-element">
                        <WmsInput
                            id='City'
                            inputName='City'
                            wmsSelector={this.props.selector + 'City'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Return_City_District')}
                            value={renderSettings.City}
                            extraWrapperClass="city-wrapper" />

                        <WmsDropdown
                            id="State"
                            name="State"
                            label={this.translate("Label_State_Province_Region_Territory")}
                            wmsSelector={this.props.selector + "State"}
                            value={renderSettings.State}
                            onChangeFunc={this.handleSettingsChange}
                            options={GlobalService.GetRegions(renderSettings.Country)}
                            valueField='RegionCode'
                            textField='Name'
                            extraWrapperClass="state-wrapper"
                        />

                        <WmsInput
                            id='Zip'
                            inputName='Zip'
                            wmsSelector={this.props.selector + 'Zip'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Return_Zip')}
                            value={renderSettings.Zip}
                            extraWrapperClass="zip-wrapper" />
                    </div>

                </WmsCollapse>

                {Object.prototype.hasOwnProperty.call(this.rights, 'Shipping.SmallParcelRateShoppingEnabled') &&
                    <WmsCollapse
                        id={'SmartParcelAutoRateShopping'}
                        headerLabel={this.translate('Label_SmartParcel_Rate_Shoping_Enabled')}
                        showCollapseText={false}
                        showCollapseIcon={true}>
                        <WmsContainer title={this.translate('Label_Select_Carriers_For_Rate_Shopping')}>
                            <div className="wms-rate-shopping-chk-wrapper">
                                <div>
                                    <WmsCheckBox
                                        id={'AMZN'}
                                        name={'AMZN'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Amazon_Buy")}
                                        checked={renderArrRateShopping.indexOf('AMZN') != -1}
                                        value={renderArrRateShopping.indexOf('AMZN') != -1}
                                        wmsSelector={this.props.selector + 'AmazonByEnabled'}
                                        parentClassName="amazon-chk-wrapper" />

                                    <WmsCheckBox
                                        id={'APC'}
                                        name={'APC'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_APC")}
                                        checked={renderArrRateShopping.indexOf('APC') != -1}
                                        value={renderArrRateShopping.indexOf('APC') != -1}
                                        wmsSelector={this.props.selector + 'APC'} />

                                    <WmsCheckBox
                                        id={'AustraliaPost'}
                                        name={'Australia Post'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Australia_Post")}
                                        checked={renderArrRateShopping.indexOf('Australia Post') != -1}
                                        value={renderArrRateShopping.indexOf('Australia Post') != -1}
                                        wmsSelector={this.props.selector + 'AustraliaPostEnabled'} />

                                    <WmsCheckBox
                                        id={'CanadaPost'}
                                        name={'Canada Post'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Canada_Post")}
                                        checked={renderArrRateShopping.indexOf('Canada Post') != -1}
                                        value={renderArrRateShopping.indexOf('Canada Post') != -1}
                                        wmsSelector={this.props.selector + 'CanadaPostEnabled'}
                                        parentClassName="canada-post-wrapper" />

                                    <WmsCheckBox
                                        id={'Canpar'}
                                        name={'Canpar'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Canpar")}
                                        checked={renderArrRateShopping.indexOf('Canpar') != -1}
                                        value={renderArrRateShopping.indexOf('Canpar') != -1}
                                        wmsSelector={this.props.selector + 'CanparEnabled'} />

                                    <WmsCheckBox
                                        id={'DHLGM'}
                                        name={'DHLGM'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_DHL")}
                                        checked={renderArrRateShopping.indexOf('DHLGM') != -1}
                                        value={renderArrRateShopping.indexOf('DHLGM') != -1}
                                        wmsSelector={this.props.selector + 'DhlgmEnabled'} />

                                    <WmsCheckBox
                                        id={'ePost Global'}
                                        name={'ePost Global'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_ePostGlobal")}
                                        checked={renderArrRateShopping.indexOf('ePost Global') != -1}
                                        value={renderArrRateShopping.indexOf('ePost Global') != -1}
                                        wmsSelector={this.props.selector + 'ePost Global'} />

                                    <WmsCheckBox
                                        id={'FDXE'}
                                        name={'FDXE'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_FedEx")}
                                        checked={renderArrRateShopping.indexOf('FDXE') != -1}
                                        value={renderArrRateShopping.indexOf('FDXE') != -1}
                                        wmsSelector={this.props.selector + 'FedExEnabled'} />

                                    <WmsCheckBox
                                        id={'FedExSmartPost'}
                                        name={'FedEx SmartPost'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_FedEx_SmartPost")}
                                        checked={renderArrRateShopping.indexOf('FedEx SmartPost') != -1}
                                        value={renderArrRateShopping.indexOf('FedEx SmartPost') != -1}
                                        wmsSelector={this.props.selector + 'FedExSmartPost'} />

                                    <WmsCheckBox
                                        id={'Firstmile'}
                                        name={'Firstmile'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Firstmile")}
                                        checked={renderArrRateShopping.indexOf('Firstmile') != -1}
                                        value={renderArrRateShopping.indexOf('Firstmile') != -1}
                                        wmsSelector={this.props.selector + 'Firstmile'} />

                                    <WmsCheckBox
                                        id={'Flexport'}
                                        name={'Flexport'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Flexport")}
                                        checked={renderArrRateShopping.indexOf('Flexport') != -1}
                                        value={renderArrRateShopping.indexOf('Flexport') != -1}
                                        wmsSelector={this.props.selector + 'Flexport'} />

                                    <WmsCheckBox
                                        id={'Landmark'}
                                        name={'Landmark'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Landmark")}
                                        checked={renderArrRateShopping.indexOf('Landmark') != -1}
                                        value={renderArrRateShopping.indexOf('Landmark') != -1}
                                        wmsSelector={this.props.selector + 'Landmark'} />

                                    <WmsCheckBox
                                        id={'Lasership'}
                                        name={'Lasership'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Lasership")}
                                        checked={renderArrRateShopping.indexOf('Lasership') != -1}
                                        value={renderArrRateShopping.indexOf('Lasership') != -1}
                                        wmsSelector={this.props.selector + 'Lasership'} />

                                    <WmsCheckBox
                                        id={'OnTrac'}
                                        name={'OnTrac'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_OnTrac")}
                                        checked={renderArrRateShopping.indexOf('OnTrac') != -1}
                                        value={renderArrRateShopping.indexOf('OnTrac') != -1}
                                        wmsSelector={this.props.selector + 'OnTrac'} />

                                    <WmsCheckBox
                                        id={'PARCLL'}
                                        name={'PARCLL'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_PARCLL")}
                                        checked={renderArrRateShopping.indexOf('PARCLL') != -1}
                                        value={renderArrRateShopping.indexOf('PARCLL') != -1}
                                        wmsSelector={this.props.selector + 'PARCLL'} />

                                    <WmsCheckBox
                                        id={'Passport'}
                                        name={'Passport'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Passport")}
                                        checked={renderArrRateShopping.indexOf('Passport') != -1}
                                        value={renderArrRateShopping.indexOf('Passport') != -1}
                                        wmsSelector={this.props.selector + 'Passport'} />

                                    {this.isEnableProvider &&
                                        <WmsCheckBox
                                            id={'PBCS'}
                                            name={'PBCS'}
                                            onChangeFunc={this.handleRateShoppingChange}
                                            label={this.translate("Label_PBCS")}
                                            checked={renderArrRateShopping.indexOf('PBCS') != -1}
                                            value={renderArrRateShopping.indexOf('PBCS') != -1}
                                            wmsSelector={this.props.selector + 'PBCS'} />
                                    }

                                    <WmsCheckBox
                                        id={'Purolator'}
                                        name={'Purolator'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Purolator")}
                                        checked={renderArrRateShopping.indexOf('Purolator') != -1}
                                        value={renderArrRateShopping.indexOf('Purolator') != -1}
                                        wmsSelector={this.props.selector + 'Purolator'} />

                                    <WmsCheckBox
                                        id={'SEKOEcommerce'}
                                        name={'SEKO'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Seko_Ecommerce")}
                                        checked={renderArrRateShopping.indexOf('SEKO') != -1}
                                        value={renderArrRateShopping.indexOf('SEKO') != -1}
                                        wmsSelector={this.props.selector + 'SEKOEcommerce'}
                                        parentClassName="seko-logistics-wrapper" />

                                    <WmsCheckBox
                                        id={'Sendle'}
                                        name={'Sendle'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Sendle")}
                                        checked={renderArrRateShopping.indexOf('Sendle') != -1}
                                        value={renderArrRateShopping.indexOf('Sendle') != -1}
                                        wmsSelector={this.props.selector + 'Sendle'} />

                                    <WmsCheckBox
                                        id={'StallionExpress'}
                                        name={'Stallion Express'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Stallion_Express")}
                                        checked={renderArrRateShopping.indexOf('Stallion Express') != -1}
                                        value={renderArrRateShopping.indexOf('Stallion Express') != -1}
                                        wmsSelector={this.props.selector + 'StallionExpress'} />

                                    <WmsCheckBox
                                        id={'TuskLogistics'}
                                        name={'Tusk Logistics'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_Tusk")}
                                        checked={renderArrRateShopping.indexOf('Tusk Logistics') != -1}
                                        value={renderArrRateShopping.indexOf('Tusk Logistics') != -1}
                                        wmsSelector={this.props.selector + 'TuskLogistics'} />

                                    <WmsCheckBox
                                        id={'UPS'}
                                        name={'UPS'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_UPS")}
                                        checked={renderArrRateShopping.indexOf('UPS') != -1}
                                        value={renderArrRateShopping.indexOf('UPS') != -1}
                                        wmsSelector={this.props.selector + 'UpsEnabled'} />

                                    <WmsCheckBox
                                        id={'UPSMailInnovations'}
                                        name={'UPS Mail Innovations'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_UPS_Mail_Innovations")}
                                        checked={renderArrRateShopping.indexOf('UPS Mail Innovations') != -1}
                                        value={renderArrRateShopping.indexOf('UPS Mail Innovations') != -1}
                                        wmsSelector={this.props.selector + 'UPSMailInnovations'} />

                                    <WmsCheckBox
                                        id={'UPSSurePost'}
                                        name={'UPS SurePost'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_UPS_SurePost")}
                                        checked={renderArrRateShopping.indexOf('UPS SurePost') != -1}
                                        value={renderArrRateShopping.indexOf('UPS SurePost') != -1}
                                        wmsSelector={this.props.selector + 'UPSSurePost'} />

                                    <WmsCheckBox
                                        id={'USPS'}
                                        name={'USPS'}
                                        onChangeFunc={this.handleRateShoppingChange}
                                        label={this.translate("Label_USPS")}
                                        checked={renderArrRateShopping.indexOf('USPS') != -1}
                                        value={renderArrRateShopping.indexOf('USPS') != -1}
                                        wmsSelector={this.props.selector + 'UspsEnabled'} />
                                </div>
                            </div>
                            <WmsButton
                                label={this.translate("Label_Edit_Service_Levels")}
                                wmsSelector={this.selector + "CarrierServiceLevels"}
                                onClickFunc={this.openCarrierServiceLevels}
                                icon="fa-pen"
                                showToolTip={renderArrRateShopping.length == 0}
                                disabled={renderArrRateShopping.length == 0}
                                tooltipText={this.translate('Edit_Service_Levels_Disable_Text')} />
                            {renderSettings.SmallParcelCarrierSelectionV2 && (
                                <WmsButton
                                    label={this.translate("Label_Select_Accounts")}
                                    wmsSelector={this.selector + "SelectCarrierAccounts"}
                                    onClickFunc={this.openSelectCarrierAccounts}
                                    icon="fa-pen"
                                    showToolTip={renderArrRateShopping.length == 0}
                                    disabled={renderArrRateShopping.length == 0}
                                    tooltipText={this.translate('Select_Accounts_Disable_Text')} />
                            )}
                        </WmsContainer>
                        <WmsContainer title={this.translate('Label_Auto_Rate_Shopping_Settings')}>
                            <div className="wms-auto-rate-shopping-chk-wrapper">
                                <WmsCheckBox
                                    id={'SmallParcelAutoRateShopOnShip'}
                                    name={'SmallParcelAutoRateShopOnShip'}
                                    onChangeFunc={this.handleSettingsChange}
                                    label={this.translate("Label_Auto_Rate_Shop_On_Ship")}
                                    checked={renderSettings.SmallParcelAutoRateShopOnShip}
                                    value={renderSettings.SmallParcelAutoRateShopOnShip}
                                    wmsSelector={this.props.selector + 'zplPrintDefault'} />
                            </div>
                        </WmsContainer>
                        <WmsGrid
                            wmsSelector={this.props.selector + 'gridRateShoppingLogic'}
                            isAlignIndexesByProperty={true}
                            gridKeySelection={'Mapping'}
                            gridName={'gridRateShoppingLogic'}
                            virtual={false}
                            layoutData={''}
                            getGridSelectionUrl={''}
                            searchUrl={''}
                            id={'gridRateShoppingLogic'}
                            columns={this.rateShoppingGridColumns}
                            schema={this.rateShoppingGridSchema}
                            staticGridData={renderSettings.SmallParcelRateShoppingLogic ? renderSettings.SmallParcelRateShoppingLogic : []}
                            onDataBound={() => function () { }}
                            onRemove={this.onRemove}
                            onSelection={() => function () { }}
                            resetButtonLabel={this.translate('Label_Options')}
                            resetButtonIcon={'fa-filter'}
                            gridEditable={{ confirmation: false }}
                            menu={[{
                                "name": this.translate('Label_Add'),
                                "value": [],
                                "disabled": false,
                                "icon": 'fa-plus',
                                "color": "green",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'wmsid': 'expandCollapse',
                                'wmsSelector': this.props.selector + "AddLogic",
                                'clickFunc': this.openAddAutoRateShoppingRuleModel,
                            }]}
                            shawCheckbox={false}
                            showResetButton={true}
                            showColumnFilter={true}
                            showSortColumns={true} />
                        {this.state.isOpenAddAutoRateShoppingRuleModel && (
                            <AutoRateShoppingRuleModel
                                selector={this.props.selector}
                                eventClickAddLogic={this.eventClickAddLogic}
                                sharedSmallParcelSettings={this.props.sharedSmallParcelSettings}
                                handleSettingsChange={this.handleSettingsChange}
                                closeAddAutoRateShoppingRuleModel={this.closeAddAutoRateShoppingRuleModel}
                                isOpenAddAutoRateShoppingRuleModel={this.state.isOpenAddAutoRateShoppingRuleModel} />
                        )}
                    </WmsCollapse>
                }
                {this.state.isOpenCarrierMarkupModel && (
                    <CarrierMarkupModel
                        isOpenCarrierMarkupModel={this.state.isOpenCarrierMarkupModel}
                        sharedSelectedCustomer={this.props.sharedSelectedCustomer}
                        closeCarrierMarkup={this.closeCarrierMarkup}
                        selector={this.props.selector}
                        carrierShipmentServices={this.state.carrierShipmentServices}
                        sharedCarrierAccountsGridData={this.props.sharedCarrierAccountsGridData}
                        onRefreshGridData={this.props.onRefreshGridData} />
                )}
                {this.state.isOpenCarrierServiceLevelsModel && (
                    <CarrierServiceLevelsModel
                        isOpenCarrierServiceLevelsModel={this.state.isOpenCarrierServiceLevelsModel}
                        sharedSelectedCustomer={this.props.sharedSelectedCustomer}
                        selectedCarrierServiceLevels={renderArrRateShopping}
                        sharedShipEngineCarrier={this.props.sharedShipEngineCarrier}
                        closeCarrierServiceLevels={this.closeCarrierServiceLevels}
                        carrierShipmentServices={this.state.carrierShipmentServices}
                        sharedSmallParcelSettings={this.props.sharedSmallParcelSettings}
                        onSaveCarrierServiceLevels={this.saveCarrierServiceLevels}
                        selector={this.props.selector} />
                )}
                {this.state.isOpenSelectCarrierAccountsModal && (
                    <CarrierSelectAccountsModal
                        wmsSelector={this.props.selector}
                        isOpenSelectAccountModal={this.state.isOpenSelectCarrierAccountsModal}
                        onCloseSelectAccountModal={this.closeSelectCarrierAccounts}
                        smallParcelSettingsData={this.props.sharedSmallParcelSettings}
                        sharedShipEngineCarrier={this.props.sharedShipEngineCarrier}
                        sharedSelectedCustomer={this.props.sharedSelectedCustomer}
                        selectedCarrierAccounts={renderArrRateShopping}
                        carrierAccountsData={this.props.sharedCarrierAccountsGridData}
                        onSaveSelectCarrierAccounts={this.saveCarrierServiceLevels}
                    />
                )}
            </div>
        );
    }
}

