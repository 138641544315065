import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import WmsModel from "./../components/WmsModel/WmsModel";
import WmsDropdown from "./../components/WmsDropdown/WmsDropdown";
import WmsDropdownList from "./../components/WmsDropdownList/WmsDropdownList";
import WmsInput from "./../components/WmsInput/WmsInput";
import WMSGridCheckBoxSelection from "../components/WMSGridCheckBoxSelection/WMSGridCheckBoxSelection";
import WmsCheckBox from "./../components/WmsCheckBox/WmsCheckBox";
import WmsButton from "./../components/WmsButton/WmsButton";
import * as GridConfig from "./audit-inventory-grid-configuration";

import "./audit-inventory.scss";
// import { StringWms } from "../../../assets/ts/StringWms";
import WmsDateTimePicker from "../components/WmsDateTimePicker/WmsDateTimePicker";
import * as auditInventoryServices from "./audit-inventory.services";
import * as GlobalService from "../global_services";

import _ from "lodash";
// import { KendoWms } from "../../../assets/ts/KendoWms";
import WmsToolTip from "../components/WmsToolTip/WmsToolTip";
import WmsFormGrid from "../components/WmsFormGrid/WmsFormGrid";

declare var window: any;

interface Props {
    onCloseManageAuditInventoryModal: any;
    isOpenManageAuditInventoryModal: boolean;
    wmsSelector: string;
    windowId: string;
    userOptions: any;
    locationTypes: any;
    getAuditOptions: any;
    facId: number;
    facUri: string;
    selectedRowData: any;
    auditType: string;
}
const defaultAuditJobObject = {
    "Name": "",
    "Notes": "",
    "Priority": 8,
    "AuditType": "Item",
    "UseTrackBys": false,
    "Locations": [],
    "AssignedTo": { Id: "" },
    "EditUri": "",
    "ItemId": "",
};

const defaultFilterObject = {
    "LocationName": "",
    "LastCounted": "",
    "LocationType": "",
    "ItemId": "",
    "customerId": 0,
    "pastndays": 60,
};

const ManageAuditInventoryModal: React.FC<Props> = (props: Props): any => {
    const CustomerOptions = GlobalService.getCustomerList(true);
    // const optionContains = StringWms.convertSringToOption(window.translate("Options_SearchBy_StringOptions"));
    const defaultItemsColumns = GridConfig.itemsGridColumns();
    const defaultLocationsColumns = GridConfig.locationsGridColumns();
    const [schema] = useState(GridConfig.locationsGridSchema().schema);
    const [jobLocationsSchema] = useState(GridConfig.jobLocationsGridSchema().schema);
    const [assignedToOptions] = useState(props.userOptions);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [locationNoOfSelection, setLocationNoOfSelection] = useState(0);
    const [jobLocationsGridData, setJobLocationsGridData] = useState([]);
    const [auditJobObj, setAuditJobObj] = useState(defaultAuditJobObject);
    const [locationGridKeyNumber, setLocationGridKeyNumber] = useState(0);
    const [filterObj, setFilterObj] = useState(defaultFilterObject);
    const [locationType, setLocationType] = useState("suggested");
    const jobLocationColumns = GridConfig.jobLocationsGridColumns(auditJobObj.AuditType);
    const [locationTypes] = useState(props.locationTypes);
    const [isSaveAuditJobLoading, setIsSaveAuditJobLoading] = useState(false);
    const [itemSize] = useState([
        { text: "60 Days", value: 60 },
        { text: "90 Days", value: 90 },
        { text: "120 Days", value: 120 },
        { text: "180 Days", value: 180 },
    ]);
    const [auditTypeOptions] = useState([
        {
            name: "Item",
            value: "Item",
        },
        {
            name: "Location",
            value: "Location",
        },
    ]);
    const [jobPriorityOption] = useState([
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
    ]);
    const lastCountedDateFormat = "MMM dd,yyyy";

    useEffect(() => {
        if (props.auditType === "Edit") {
            var assignedTo = props.selectedRowData.AssignedTo?.Id !== 0 ? props.selectedRowData.AssignedTo : { Id: "" };
            setAuditJobObj({
                "Name": props.selectedRowData.Name,
                "Notes": props.selectedRowData.Notes,
                "Priority": props.selectedRowData.Priority,
                "AuditType": props.selectedRowData.AuditType,
                "UseTrackBys": props.selectedRowData.UseTrackBys,
                "Locations": props.selectedRowData.Locations,
                "AssignedTo": assignedTo,
                "EditUri": props.selectedRowData.EditUri,
                "ItemId": props.selectedRowData.ItemId,
            });
            setFilterObj((prevState: any) => ({
                ...prevState,
                customerId: props.selectedRowData.CustomerId,
            }));
            let tmpJobLocation = [];
            for (let i = 0; i < props.selectedRowData.Locations.length; i++) {
                // eslint-disable-next-line security/detect-object-injection
                const location = props.selectedRowData.Locations[i];
                location.IsRequired = false;
                tmpJobLocation.push(location);
            }
            setJobLocationsGridData(tmpJobLocation);
            setLocationGridKeyNumber((ps) => ps + 1);
        }
    }, [props.selectedRowData, props.auditType]);

    function selectRow(selectedIdObj) {
        setLocationNoOfSelection(Object.keys(selectedIdObj).length);
    }

    const onChangeJobFields = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "AssignedTo") {
            value = { Id: value.Id };
        } else if (name === "UseTrackBys") {
            value = e.target.checked;
        } else if (name === "AuditType") {
            resetGridsData(true);
            setFilterObj(defaultFilterObject);
            onFilterChangeDebounceCall();
            setLocationGridKeyNumber((ps) => ps + 1);
        }
        setAuditJobObj((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const moveLocationToJob = () => {
        let grid = $(`#${props.wmsSelector}LocationGrid`).data("kendoGrid");
        let selectedGridItem = grid.select();
        // eslint-disable-next-line sonarjs/no-unused-collection
        let selectionArr = [];
        for (let i = 0; i < selectedGridItem.length; i++) {
            // eslint-disable-next-line security/detect-object-injection
            let itemObj = selectedGridItem[i];
            if (itemObj) {
                const selectedItem = grid.dataItem(itemObj);
                if (selectedItem && !jobLocationsGridData.some((loc) => loc["Id"] === selectedItem["Id"])) {
                    selectionArr.push(selectedItem);
                    setTimeout(() => {
                        grid.removeRow(itemObj);
                    }, 100);
                }
            }
        }
        let tmpSelectedLocationsItem = _.cloneDeep(jobLocationsGridData);
        setJobLocationsGridData(_.concat(tmpSelectedLocationsItem, selectionArr));
        resetGridsData(false);
        setTimeout(() => {
            setLocationGridKeyNumber((ps) => ps + 1);
        }, 500);
        setLocationNoOfSelection(0);
    };
    const moveJobToLocation = () => {
        let auditJobLocationGrid = $("#" + props.wmsSelector + "AuditJobLocation").data("kendoGrid");
        let selectedAuditJobLocationGrid = auditJobLocationGrid.select();
        let locationIdsArr = [];
        if (selectedAuditJobLocationGrid.length > 0) {
            for (let i = 0; i < selectedAuditJobLocationGrid.length; i++) {
                // eslint-disable-next-line security/detect-object-injection
                let itemObj = selectedAuditJobLocationGrid[i];
                if (itemObj) {
                    const selectedItem = auditJobLocationGrid.dataItem(itemObj);
                    if (selectedItem) {
                        locationIdsArr.push(selectedItem["Id"]);
                        setTimeout(() => {
                            auditJobLocationGrid.removeRow(itemObj);
                        }, 100);
                    }
                }
            }
            let clonedJobLocationsGridData = _.cloneDeep(jobLocationsGridData);
            let tmpSelectedLocationsItem = clonedJobLocationsGridData.filter((x) => !locationIdsArr.includes(x.Id));
            setJobLocationsGridData(tmpSelectedLocationsItem);
            resetGridsData(false);
            setTimeout(() => {
                setLocationGridKeyNumber((ps) => ps + 1);
            }, 500);
        }
    };
    const onRequestEnd = (e) => {
        if (!props.isOpenManageAuditInventoryModal) {
            return;
        }
        // setIsChangeColumn(false);
        let locationData = [];
        if (auditJobObj.AuditType === "Item") {
            if (locationType === "suggested") {
                locationData = e.response.Data[0].RecentStockLocationList;
                locationData.map((location) => {
                    location.IsSuggested = true;
                    location.IsRequired = false;
                    return location;
                });
            } else if (locationType === "other") {
                locationData = e.response.Data[0].OtherLocationList;
                locationData.map((location) => (location.IsRequired = false));
            }
            const requiredLocations = e.response.Data[0].AuditLocations;
            requiredLocations.map((location) => {
                location.IsRequired = true;
                locationData.map((l) => (l.IsRequired = location.Id === l.Id));
                return location;
            });
            locationData.unshift(...requiredLocations);
        } else {
            locationData = e.response.Data.map((location) => {
                location.IsRequired = false;
                return location;
            });
        }
        var gridData = loadRequiredAuditLocations(locationData);
        const locationIdsArr = _.map(gridData, "Id");
        let tempArr = locationData.filter((x) => locationIdsArr.indexOf(x.Id) === -1);
        _.forEach(tempArr, function (item) {
            item.LastCounted = kendo.toString(kendo.parseDate(item.LastCounted), lastCountedDateFormat);
        });
        e.response.Data = tempArr;
        setJobLocationsGridData(gridData);
        return e;
    };

    const resetGridsData = (clearJobLocationDataYn) => {
        let locationGrid = $("#" + props.wmsSelector + "LocationGrid").data("kendoGrid");
        // defaultLocationFilterObject.Location = "";
        // auditInventoryServices.locationGridSearchInfoSend(defaultLocationFilterObject);
        if (locationGrid) {
            if (clearJobLocationDataYn) {
                setJobLocationsGridData([]);
            }
            locationGrid.dataSource.data([]);
        }
    };

    const loadRequiredAuditLocations = (locationsData) => {
        if (locationsData) {
            let auditJobLocationData = [...jobLocationsGridData];
            let tmpAuditJobLocationData = _.cloneDeep(auditJobLocationData);
            let requiredLocations = [];
            if (auditJobObj.AuditType === "Item") {
                for (let locationIndex = 0; locationIndex < locationsData.length; locationIndex++) {
                    // eslint-disable-next-line security/detect-object-injection
                    let locationObj = locationsData[locationIndex];
                    tmpAuditJobLocationData.map((l) => {
                        if (l.Id === locationObj.Id) {
                            l.IsRequired = locationObj.IsRequired;
                        }
                        return l;
                    });
                    if (
                        locationObj &&
                        locationObj.IsRequired &&
                        !auditJobLocationData.some((loc) => loc["Id"] === locationObj.Id)
                    ) {
                        requiredLocations.push(locationObj);
                    }
                }
            }
            return _.concat(tmpAuditJobLocationData, requiredLocations);
        }
        return [];
    };

    const onItemFilterChange = (event) => {
        if (event.dataItem !== undefined && event.dataItem.Id !== "") {
            setAuditJobObj((prevState: any) => ({
                ...prevState,
                ItemId: event.dataItem.Id,
            }));
            resetGridsData(true);
            setLocationGridKeyNumber((ps) => ps + 1);
        }
    };
    const onFilterChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilterObj((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "customerId") {
            setAuditJobObj((prevState: any) => ({
                ...prevState,
                ItemId: "",
            }));
        }
        onFilterChangeDebounceCall();
    };
    const onChangeLocationType = (e) => {
        if (
            (locationType === "suggested" && e.target.name === "isSuggestedLocation") ||
            (locationType === "other" && e.target.name === "isOtherLocation")
        ) {
            setLocationType("");
        } else if (e.target.name === "isSuggestedLocation") {
            setLocationType("suggested");
        } else if (e.target.name === "isOtherLocation") {
            setLocationType("other");
        }
        resetGridsData(false);
        setLocationGridKeyNumber((ps) => ps + 1);
    };
    const onFilterChangeDebounce = () => {
        auditInventoryServices
            .locationGridSearchInfoSend({
                "IndexOptionsLocation": 0,
                "LocationType": 0,
                "Location": filterObj.LocationName,
                "LastCountedDate": null,
                "ShowLastCountedDate": true,
                "ShowLocationType": true,
                "ShowLocation": true,
            })
            .then((response) => {
                let auditJobLocation = $("#" + props.wmsSelector + "LocationGrid").data("kendoGrid");
                let dataArr = [];

                if (filterObj.LastCounted) {
                    dataArr.push({
                        field: "LastCounted",
                        operator: "eq",
                        value: kendo.toString(kendo.parseDate(filterObj.LastCounted), lastCountedDateFormat),
                    });
                }
                if (filterObj.LocationType && filterObj.LocationType !== "All") {
                    dataArr.push({
                        field: "Type",
                        operator: "eq",
                        value: filterObj.LocationType,
                    });
                }
                if (auditJobLocation) {
                    auditJobLocation.dataSource.filter(dataArr);
                }
            });
    };
    let onFilterChangeDebounceCall = GlobalService.useFCDebounce(onFilterChangeDebounce, 1000);
    const onSaveAuditJob = () => {
        setIsSaveAuditJobLoading(true);
        let locationsId = [];
        for (let i = 0; i < jobLocationsGridData.length; i++) {
            // eslint-disable-next-line security/detect-object-injection
            const location = jobLocationsGridData[i];
            locationsId.push({ "Id": location.Id });
        }
        let tmpAuditJob = auditJobObj;
        if (tmpAuditJob.AssignedTo && !tmpAuditJob.AssignedTo.Id) {
            delete tmpAuditJob.AssignedTo;
        }
        tmpAuditJob.Locations = locationsId;
        tmpAuditJob.ItemId = auditJobObj.ItemId;
        auditInventoryServices
            .auditJob(auditJobObj, props.facUri, props.auditType)
            .then((response) => {
                GlobalService.notification(
                    "success",
                    window.translate(
                        props.auditType === "Create"
                            ? "Label_Job_Created_Successfully"
                            : "Label_Job_Updated_Successfully"
                    )
                );
                props.getAuditOptions(props.facUri);
                props.onCloseManageAuditInventoryModal();
            })
            .finally(() => {
                setIsSaveAuditJobLoading(false);
            });
    };

    return (
        <WmsModel
            id="ManageAuditInventoryModal"
            title={
                props.auditType === "Create" ? window.translate("Create_Audit_Job") : window.translate("Edit_Audit_Job")
            }
            width={1200}
            height={800}
            onCloseFunc={props.onCloseManageAuditInventoryModal}
            isOpen={props.isOpenManageAuditInventoryModal}
            customClass="wms-model-new-ui-wrapper"
            actions={["Minimize", "Maximize", "Close"]}
            overlayHide={false}
            windowId={props.windowId}
            appendTo={"#" + props.windowId}
        >
            <div className={"model-content-wrapper"}>
                <div className="general-fields-Wrapper">
                    <WmsInput
                        inputName="Name"
                        wmsSelector={props.wmsSelector + "SearchJobName"}
                        onChangeFunc={onChangeJobFields}
                        label={window.translate("Label_Cycle_count_Job_Name")}
                        required={true}
                        value={auditJobObj.Name}
                        extraWrapperClass={"job-name-wrapper"}
                    />
                    <WmsDropdown
                        id="AuditType"
                        name="AuditType"
                        label={window.translate("Label_Audit_Type")}
                        wmsSelector={props.wmsSelector + "AuditType"}
                        onChangeFunc={onChangeJobFields}
                        value={auditJobObj.AuditType}
                        options={auditTypeOptions}
                        valueField="value"
                        textField="name"
                        extraWrapperClass="audit-type-wrapper form_group"
                    />
                    <WmsDropdownList
                        id={"AssignedTo"}
                        name="AssignedTo"
                        label={window.translate("Label_Assign_To")}
                        wmsSelector={props.wmsSelector + "AssignedTo"}
                        value={auditJobObj.AssignedTo ? auditJobObj.AssignedTo.Id : null}
                        onChangeFunc={onChangeJobFields}
                        valueField="Id"
                        textField="Name"
                        isVirtualOptions={false}
                        staticOptions={assignedToOptions}
                        extraWrapperClass="assigned-to-wrapper"
                    />
                    <WmsDropdown
                        id="Priority"
                        name="Priority"
                        label={window.translate("Label_Priority")}
                        wmsSelector={props.wmsSelector + "Priority"}
                        onChangeFunc={onChangeJobFields}
                        options={jobPriorityOption}
                        value={auditJobObj.Priority}
                        valueField="value"
                        textField="text"
                        extraWrapperClass="priority-wrapper form_group"
                    />
                    <WmsCheckBox
                        id={"UseTrackBys"}
                        name={"UseTrackBys"}
                        wmsSelector={props.wmsSelector + "SearchTrackBys"}
                        onChangeFunc={onChangeJobFields}
                        checked={auditJobObj.UseTrackBys}
                        label={window.translate("Label_Collect_TrackBy")}
                    />
                </div>
                <div className="grid-fields-Wrapper">
                    {auditJobObj.AuditType === "Item" ? (
                        <div className="item-filter-wrapper">
                            <WmsDropdown
                                id="customerId"
                                name="customerId"
                                label={window.translate("Label_Customer_Text")}
                                wmsSelector={props.wmsSelector + "customerId"}
                                onChangeFunc={onFilterChange}
                                value={filterObj.customerId}
                                options={CustomerOptions}
                                textField={"name"}
                                valueField={"id"}
                                required={auditJobObj.AuditType === "Item"}
                                extraWrapperClass="customer-options-wrapper form_group"
                            />
                            <WmsToolTip
                                tooltipText={
                                    "Select the duration of item history used to identify suggested locations for this audit job."
                                }
                            >
                                <WmsDropdown
                                    id="pastndays"
                                    name="pastndays"
                                    label={window.translate("Label_Item_History_Size")}
                                    wmsSelector={props.wmsSelector + "pastndays"}
                                    onChangeFunc={onFilterChange}
                                    options={itemSize}
                                    valueField="value"
                                    textField="text"
                                    extraWrapperClass="select-an-item-wrapper form_group"
                                    value={filterObj.pastndays}
                                />
                            </WmsToolTip>
                            {filterObj.customerId !== 0 && (
                                <WmsDropdownList
                                    key={"AuditInventoryDropDownList" + auditJobObj.ItemId}
                                    id="AuditInventoryDropDownList"
                                    name="AuditInventoryDropDownList"
                                    label={window.translate("Label_Item")}
                                    optionsLabel={window.translate("Label_Select_Sku")}
                                    wmsSelector={props.wmsSelector + "audit-inventory-SkuDropDown"}
                                    onSelect={onItemFilterChange}
                                    value={auditJobObj.ItemId}
                                    disabled={!filterObj.customerId}
                                    onChangeFunc={() => {}}
                                    textField="Sku"
                                    valueField="Id"
                                    showDetailsTooltip={false}
                                    windowId={props.windowId}
                                    parentclassName="form-group"
                                    extraWrapperClass="select-an-item-wrapper form_group"
                                    required={auditJobObj.AuditType === "Item"}
                                    virtualObj={{
                                        itemHeight: 45,
                                        valueMapper: (options) => {
                                            var valueMapperOptions = options;
                                            $.ajax({
                                                url: "/WebUI/Inventory/EditAudits/SkuDropdownIndexRead",
                                                type: "POST",
                                                dataType: "json",
                                                data: {
                                                    itemId: valueMapperOptions.value || 0,
                                                    customerId: filterObj.customerId || 0,
                                                },
                                                success: function (data) {
                                                    options.success(data);
                                                },
                                            });
                                        },
                                    }}
                                    dataSourceObj={{
                                        type: "aspnetmvc-ajax",
                                        pageSize: 30,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        transport: {
                                            read: {
                                                url: "/WebUI/Inventory/EditAudits/SkuDropDownRead",
                                                type: "POST",
                                                data: {
                                                    customerId: filterObj.customerId,
                                                },
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total",
                                        },
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="locations-filter-wrapper">
                            <div className="wms-input-group-wrapper">
                                <WmsInput
                                    inputName="LocationName"
                                    wmsSelector={props.wmsSelector + "SearchLocName"}
                                    value={filterObj.LocationName}
                                    onChangeFunc={onFilterChange}
                                    label={window.translate("Label_Location")}
                                    parentClassName="input_box"
                                />
                            </div>
                            <WmsDateTimePicker
                                id={"LastCounted"}
                                name="LastCounted"
                                label={window.translate("Label_Cycle_count_Last_Counted")}
                                onChangeFunc={onFilterChange}
                                value={filterObj.LastCounted}
                                required={false}
                                wmsSelector={props.wmsSelector + "lastCounted"}
                                format={lastCountedDateFormat}
                                isDateParseInCustomFormat={true}
                                onlyDate={true}
                            />
                            <WmsDropdown
                                id="LocationType"
                                name="LocationType"
                                label={window.translate("Label_Location_Type")}
                                wmsSelector={props.wmsSelector + "LocationType"}
                                onChangeFunc={onFilterChange}
                                value={filterObj.LocationType}
                                options={locationTypes}
                                valueField="Name"
                                textField="Name"
                                extraWrapperClass="form_group"
                            />
                        </div>
                    )}
                </div>
                {auditJobObj.AuditType === "Item" && (
                    <div className="location-icon-wrapper">
                        <div className="location-type-Wrapper">
                            <div className="label-wrapper">
                                <label>{window.translate("Label_Show_Location_Type")}</label>
                            </div>
                            <div className="radio-wrapper">
                                <WmsCheckBox
                                    id="isSuggestedLocation"
                                    name="isSuggestedLocation"
                                    label={window.translate("Label_Suggested")}
                                    wmsSelector={props.wmsSelector + "adjustment-item-Add-inventory"}
                                    checked={locationType === "suggested"}
                                    onChangeFunc={onChangeLocationType}
                                />
                                <WmsCheckBox
                                    id="isOtherLocation"
                                    name="isOtherLocation"
                                    label={window.translate("Label_Other")}
                                    wmsSelector={props.wmsSelector + "adjustment-item-Add-inventory"}
                                    checked={locationType === "other"}
                                    onChangeFunc={onChangeLocationType}
                                />
                            </div>
                        </div>
                        <div className="icon-header-wrapper">
                            <div className="required-header-wrapper">
                                <span className="required-wrapper header-label">
                                    <label>R</label>
                                </span>{" "}
                                = Required
                            </div>
                            <div className="suggested-header-wrapper">
                                <span className="required-wrapper header-label">
                                    <label>S</label>
                                </span>{" "}
                                = Suggested
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    {/* eslint-disable */}
                    {(auditJobObj.AuditType === "Location" || (filterObj.customerId && auditJobObj.ItemId)) ? (
                        <div className="grid-wrapper">
                            <div className="job-location-grid-wrapper">
                                <WMSGridCheckBoxSelection
                                    key={props.wmsSelector + "LocationGrid" + locationGridKeyNumber}
                                    id={props.wmsSelector + "LocationGrid"}
                                    transport={
                                        GridConfig.getGridDemoAuditLocationsGridRead(
                                            props.facId,
                                            auditJobObj.AuditType,
                                            filterObj.pastndays,
                                            auditJobObj.ItemId,
                                            locationType === "suggested"
                                        ).transport
                                    }
                                    schema={schema}
                                    columns={auditJobObj.AuditType === "Item" ? defaultItemsColumns : defaultLocationsColumns}
                                    onRequestEnd={onRequestEnd}
                                    onSelectRow={selectRow}
                                    changeRemoteGridColumn={false}
                                />
                            </div>
                            <div className="navigation-btn-wrapper">
                                <WmsButton
                                    label={""}
                                    wmsSelector={props.wmsSelector + "rightNavigate"}
                                    onClickFunc={moveLocationToJob}
                                    // disabled={locationNoOfSelection === 0}
                                    icon="fa-chevron-right"
                                />
                                <WmsButton
                                    label={""}
                                    wmsSelector={props.wmsSelector + "leftNavigate"}
                                    onClickFunc={moveJobToLocation}
                                    icon="fa-chevron-left"
                                />
                            </div>
                            <div className="audit-job-location-grid-wrapper">
                                <WMSGridCheckBoxSelection
                                    id={props.wmsSelector + "AuditJobLocation"}
                                    staticGridData={jobLocationsGridData}
                                    schema={jobLocationsSchema}
                                    columns={jobLocationColumns}
                                    changeColumnsDynamic={true}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={"grid-empty-block grid-wrapper"}>
                            <div className="job-location-grid-wrapper">
                                <WmsFormGrid
                                    id={"LocationGridEmpty"}
                                    wmsSelector={"LocationGridEmpty"}
                                    schema={schema}
                                    columns={defaultItemsColumns}
                                    staticGridData={[]}
                                    isColumnMenuenable={true}
                                    isColumnsFilterable={true}
                                    isColumnsResizable={true}
                                    isColumnsReorderable={true}
                                    parentClassName={"LocationGridEmpty"}
                                />
                            </div>
                            <div className="navigation-btn-wrapper">
                                <WmsButton
                                    label={""}
                                    wmsSelector={props.wmsSelector + "rightNavigateEmpty"}
                                    icon="fa-chevron-right"
                                    onClickFunc={() => { }}
                                />
                                <WmsButton
                                    label={""}
                                    wmsSelector={props.wmsSelector + "leftNavigateEmpty"}
                                    icon="fa-chevron-left"
                                    onClickFunc={() => { }}
                                />
                            </div>
                            <div className="audit-job-location-grid-wrapper">
                                <WmsFormGrid
                                    id={"AuditJobLocationEmpty"}
                                    wmsSelector={"AuditJobLocationEmpty"}
                                    schema={jobLocationsSchema}
                                    columns={jobLocationColumns}
                                    staticGridData={[]}
                                    parentClassName={"AuditJobLocationEmpty"}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={props.wmsSelector + "DiscardExit"}
                    onClickFunc={props.onCloseManageAuditInventoryModal}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                <WmsButton
                    label={window.translate("Label_Save")}
                    wmsSelector={props.wmsSelector + "Save"}
                    onClickFunc={onSaveAuditJob}
                    disabled={
                        !auditJobObj.Name ||
                        jobLocationsGridData.length === 0 ||
                        (auditJobObj.AuditType === "Item" ? !filterObj.customerId || !auditJobObj.ItemId : false)
                    }
                    icon={"fa-floppy-o"}
                    loading={isSaveAuditJobLoading}
                    parentClassName={"apply-adjustment-button"}
                />
            </div>
        </WmsModel>
    );
};
ManageAuditInventoryModal.propTypes = {
    onCloseManageAuditInventoryModal: PropTypes.any.isRequired,
    isOpenManageAuditInventoryModal: PropTypes.bool.isRequired,
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    auditType: PropTypes.string,
};

ManageAuditInventoryModal.defaultProps = {
    onCloseManageAuditInventoryModal: (): void => {},
    isOpenManageAuditInventoryModal: false,
    wmsSelector: "",
    windowId: "",
    auditType: "Create",
};

export default React.memo(ManageAuditInventoryModal);
